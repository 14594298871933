<template>
  <div class="product-container position-relative">
    <ProductHeader
      v-if="selectedItems.length < 2"
      :creating="newProduct"
      :editing="editProduct"
      :loading="createItemLoading"
      :product="product"
      :product-data="productData"
      @close="closeRightSidebar"
      @create="createDirectoryItem"
      @update="updateDirectoryItem(product.id)"
      @edit-product="editProduct = $can('edit', 'directory-item-update')"
    />

    <template v-if="productData && !newProduct && selectedItems.length < 2">
      <zem-collapse
        v-if="$can('edit' || null, 'directory-property-read')"
        :title="$t('Subgroup Characteristics').toString()"
        class="properties"
        is-opened-default
      >
        <div
          v-for="(item, i) in productData.data"
          :key="'d' + i"
          class="zem-collapse-table__row properties"
        >
          <div class="zem-collapse-table__column title d-flex align-center">
            {{ item.title }}
            <img
              v-if="$can('edit', 'directory-property-delete')"
              v-tippy
              :content="$t('Delete')"
              :src="require('@/assets/icons/close-r.svg')"
              alt=""
              class="delete_item"
              @click="
                showModalDelete = true;
                deletedId = i;
              "
            />
          </div>
        </div>
        <div v-if="newProperty" class="zem-collapse-table__row properties">
          <div class="zem-collapse-table__column">
            <ZemDropdownList
              v-model="selected"
              :loading="propertiesLoading"
              :options="properties"
              type="object"
              @open="getOptionsForProperties"
            />
          </div>
          <img
            :src="require('@/assets/icons/check_primary.svg')"
            alt=""
            class="block-id__edit"
            @click="addProperty"
          />
          <div class="zem-collapse-table__column end_delete">
            <div
              class="zem-collapse-table__row text text-h cursor-pointer"
              @click="
                newProperty = false;
                selected = { title: '', edit: false };
              "
            >
              <img
                v-if="$can('edit', 'directory-property-delete')"
                v-tippy
                :content="$t('Cancel')"
                :src="require('@/assets/icons/close-r.svg')"
                alt=""
                class="delete_item"
              />
            </div>
          </div>
        </div>

        <div
          v-else-if="$can('edit', 'directory-property-create')"
          class="colored colored--link"
          @click="newProperty = true"
        >
          <img :src="require('@/assets/icons/plus_primary.svg')" alt="" />
          Характеристика
        </div>
      </zem-collapse>
      <zem-collapse
        is-opened-default
        :title="$t('Group mask').toString()"
        class="properties"
      >
        <template v-if="productData.data.length > 0">
          <div
            class="zem-collapse-table__row"
            style="grid-template-columns: 25% auto;"
          >
            <div class="zem-collapse-table__column">
              <ZemInput
                v-model="productData.params.title_prefix"
                class="mt-0"
                placeholder="Префикс"
              />
            </div>
            <div class="zem-collapse-table__column">
              <ZemMultiSelect
                v-model="productData.params.properties_name_mask"
                :options="productData.data"
              />
            </div>
          </div>
          <zem-button class="mt-2" @click="updateMask">{{
            $t("Save")
          }}</zem-button>
        </template>
        <template v-else>
          <p class="text-normal text-muted">{{ $t("Not characteristics") }}</p>
        </template>
      </zem-collapse>
    </template>

    <template v-else-if="product && selectedItems.length < 2">
      <template v-for="(productItem, idx) in product.schema">
        <zem-collapse
          v-if="showIncludedInKitItems(productItem)"
          :key="idx"
          :info="productItem.info"
          is-opened-default
          :title="productItem.title"
        >
          <template v-slot:header>
            <div
              v-if="productItem.identifier === 'price-list'"
              class="d-flex align-center"
            >
              <ZemRadioButton
                v-model="pricesMode"
                :disabled="(!editProduct && !newProduct) || !closeAcc([2])"
                class="mr-3 mb-0"
                label="Автозаполнение"
                value="auto"
              />
              <ZemRadioButton
                v-model="pricesMode"
                :disabled="(!editProduct && !newProduct) || !closeAcc([2])"
                class="mr-3 mb-0"
                label="Ручной ввод"
                value="manual"
              />
            </div>
          </template>

          <!-- Общая информация -->
          <ProductGeneralBlock
            v-if="productItem.identifier === 'general'"
            :disabled="disabledElements"
            :edit-product="editProduct && $can('edit', 'directory-item-update')"
            :idx="idx"
            :new-product="newProduct"
            :product="productItem"
            @update-schema="updateSchema"
          />

          <!-- Дополнительные параметры -->
          <ProductParamsBlock
            v-if="productItem.identifier === 'params'"
            :disabled="disabledElements"
            :edit-product="editProduct && $can('edit', 'directory-item-update')"
            :idx="idx"
            :new-product="newProduct"
            :product="productItem"
            @update-schema="updateSchema"
          />

          <!-- Название -->
          <ProductNameBlock
            v-else-if="productItem && productItem.identifier === 'title'"
            :disabled="disabledElements"
            :edit-product="editProduct && $can('edit', 'directory-item-update')"
            :idx="idx"
            :new-product="newProduct"
            :prefix="product.prefix"
            :product="productItem"
            @update-prefix="product.prefix = $event"
          />

          <!-- Расценки -->
          <ProductPricesBlock
            v-else-if="productItem.identifier === 'price-list'"
            :edit-product="editProduct && $can('edit', 'directory-item-update')"
            :idx="idx"
            :new-product="newProduct"
            :prices-mode="pricesMode"
            :product="productItem"
          />

          <!-- Характеристики -->
          <ProductPropertiesBlock
            v-else-if="productItem.identifier === 'property'"
            :edit-product="editProduct && $can('edit', 'directory-item-update')"
            :idx="idx"
            :new-product="newProduct"
            :product="productItem"
          />

          <!-- Элементы в наборе -->
          <ProductRelatedKitItemsBlock
            v-else-if="productItem.identifier === 'related-kit-items'"
            :edit-product="editProduct && $can('edit', 'directory-item-update')"
            :idx="idx"
            :new-product="newProduct"
            :product="productItem"
          />

          <!-- Входит в наборы -->
          <ProductIncludedInKitItemsBlock
            v-else-if="productItem.identifier === 'included-in-kit-items'"
            :edit-product="editProduct && $can('edit', 'directory-item-update')"
            :idx="idx"
            :new-product="newProduct"
            :product="productItem"
          />

          <!-- Динамические характеристики -->
          <ProductDynamicPropertiesBlock
            v-else-if="productItem.identifier === 'dynamic-properties'"
            :disabled="disabledElements"
            :edit-product="editProduct && $can('edit', 'directory-item-update')"
            :idx="idx"
            :new-product="newProduct"
            :product="productItem"
          />

          <template v-else>
            <div
              v-for="(field, fieldIdx) in productItem.fields"
              :key="'f' + fieldIdx"
            >
              <div v-if="field.type === 'button'" class="zem-collapse-table">
                <div
                  v-if="editProduct || newProduct"
                  class="colored colored--link"
                  @click="addItem(productItem)"
                >
                  <img
                    :src="require('@/assets/icons/plus_primary.svg')"
                    alt=""
                  />
                  {{ field.title }}
                </div>
              </div>
            </div>
          </template>
        </zem-collapse>
      </template>
    </template>

    <ProductMultipleChanged
      v-else-if="closeAcc([5])"
      :products="productsList"
    />

    <zem-modal
      :show="showModal"
      :title-cancel="$t('No') || ''"
      :title-success="$t('Yes') || ''"
      absolute
      title="Вы подтверждаете действие?"
      @on-cancel="
        showModal = false;
        pricesMode = 'manual';
      "
      @on-success="priceConversion"
    >
      <p class="mt-3 text-normal">
        При выборе автоматического заполнения ручные значения будут удалены
      </p>
    </zem-modal>

    <zem-modal
      :show="showModalDelete"
      :title-cancel="$t('No') || ''"
      :title-success="$t('Yes') || ''"
      absolute
      title="Вы уверены, что хотите удалить характеристику?"
      @on-success="deleteProperty(deletedId)"
      @on-change="showModalDelete = false"
      @on-cancel="showModalDelete = false"
    >
      <p class="mt-3 text-normal">
        Во всех продуктах эта характеристика также будет удалена!
      </p>
    </zem-modal>

    <preloader-wrapper :show="$store.state.other.isLoadingItem" />
  </div>
</template>

<script>
import ZemCard from "@/components/ui/ZemCard";
import ZemCardTitle from "@/components/ui/ZemCardTitle";
import ZemCollapse from "@/components/ui/ZemCollapse";
import ZemInput from "@/components/ui/ZemInput";
import ZemDropdownList from "@/components/ui/ZemDropdownList";
import ZemSelect from "@/components/ui/Select";
import ZemModal from "@/components/ui/ZemModal";
import ProductsService from "@/services/products.service";
import { closeAcc, errorHandler, logger } from "@/assets/scripts/scripts";
import OtherService from "@/services/other.service";
import ZemMultiSelect from "@/components/ui/ZemMultiSelect";
import { eventBus } from "@/main";
import ZemRadioButton from "@/components/ui/ZemRadioButton";
import ZemPreloader from "@/components/ui/ZemPreloader.vue";
import ProductHeader from "@/components/Product/ProductHeader.vue";
import ProductGeneralBlock from "@/components/Product/ProductGeneralBlock.vue";
import { options } from "@/mixins/options";
import ProductNameBlock from "@/components/Product/ProductNameBlock.vue";
import ProductPricesBlock from "@/components/Product/ProductPricesBlock.vue";
import ProductPropertiesBlock from "@/components/Product/ProductPropertiesBlock.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ZemButton from "@/components/ui/ZemButton.vue";
import ProductRelatedKitItemsBlock from "@/components/Product/ProductRelatedKitItemsBlock.vue";
import ProductIncludedInKitItemsBlock from "@/components/Product/ProductIncludedInKitItemsBlock.vue";
import ProductDynamicPropertiesBlock from "@/components/Product/ProductDynamicPropertiesBlock.vue";
import PreloaderWrapper from "@/components/PreloaderWrapper.vue";
import ZemTable from "@/components/ui/ZemTable.vue";
import messages from "@/helpers/messages";
import ProductMultipleChanged from "@/components/ProductMultipleChanged.vue";
import ProductParamsBlock from "@/components/Product/ProductParamsBlock.vue";

export default {
  mixins: [options],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ProductParamsBlock,
    ProductMultipleChanged,
    ZemTable,
    PreloaderWrapper,
    ProductDynamicPropertiesBlock,
    ProductRelatedKitItemsBlock,
    ProductIncludedInKitItemsBlock,
    ZemButton,
    ProductPropertiesBlock,
    ProductPricesBlock,
    ProductNameBlock,
    ProductGeneralBlock,
    ProductHeader,
    ZemRadioButton,
    ZemMultiSelect,
    ZemCardTitle,
    ZemCard,
    ZemCollapse,
    ZemInput,
    ZemDropdownList,
    ZemSelect,
    ZemModal,
    ZemPreloader,
    VuePerfectScrollbar,
  },
  data() {
    return {
      editProduct: true,
      newProduct: false,
      productData: null,
      title: "",
      id: "",
      product: {
        title: "",
        schema: [],
      },
      properties: [],
      propertiesLoading: false,
      showModal: false,
      showModalDelete: false,
      loadOptions: [],
      currentProductId: null,
      selected: {
        title: "",
        edit: false,
      },
      productsList: [],
      groupPrefix: "",
      newProperty: false,
      deletedId: null,
      createItemLoading: false,
      pricesMode: "manual",
    };
  },
  computed: {
    disabledElements() {
      const typeIndex = this.product.schema[0].fields.findIndex(
        (el) => el["input_name"] === "type"
      );
      const positionGroupIndex = this.product.schema[0].fields.findIndex(
        (el) => el["input_name"] === "position_group_ids"
      );

      if (
        this.product.schema.length > 0 &&
        !this.editProduct &&
        this.newProduct
      ) {
        return !(
          this.product.schema[0].fields[typeIndex].data.title !== null &&
          this.product.schema[0].fields[positionGroupIndex].data.length > 0
        );
      } else {
        return false;
      }
    },
    selectedItems() {
      return this.$store.state.products.selectedItemsForDelete;
    },
  },
  watch: {
    pricesMode() {
      if (this.pricesMode === "auto") {
        this.showModal = true;
      }
    },
    selectedItems() {
      this.productsList = this.selectedItems.map((el) => {
        const product = this.products.find((product) => product.id === el);
        if (!product) return;
        return {
          id: el,
          data: product,
          fields: [
            {
              title: "Количество",
              type: "count",
              value: product.count ? product.count : 0,
            },
            {
              title: "Ед. измерения",
              type: "unit",
              value: {
                title: product.unit.data.name,
                value: product.unit.data.id,
              },
            },
            {
              title: "Родительская папка",
              type: "position_group_ids",
              value: product["positionGroups"].data.map((position) => {
                return {
                  title: position.title,
                  value: position.id,
                };
              }),
            },
          ],
        };
      });
      if (this.selectedItems.length > 1 && this.closeAcc([5])) {
        this.$store.commit("sidebars/changeRightSidebar", true);
      } else if (this.selectedItems.length > 1 && !this.closeAcc([5])) {
        this.$store.commit("sidebars/changeRightSidebar", false);
      }
    },
  },
  mounted() {
    eventBus.$on("new-element", () => {
      this.onNewElement();
    });
    eventBus.$on("copy-element", () => {
      this.onNewElement();
    });
    eventBus.$on("update-schema", (data) => {
      this.getSchema("", data.id);
      this.currentProductId = data.id;
      this.productData = null;
      this.newProduct = false;
    });
    eventBus.$on("set-category-item", (data) => {
      this.product = null;
      if (this.$route.name !== "products")
        this.$router.push({ name: "products" });
      this.$store.commit("other/onChangeLoadingItem", true);
      this.newProduct = false;
      ProductsService.getGroup(data.id)
        .then((r) => {
          this.productData = r.data.data;
          this.productData.data = r.data.data.properties.data.map((el) => {
            return {
              title: el.property.data.name,
              value: el.property.data.id,
            };
          });
          this.productData.params = Array.isArray(r.data.data.params)
            ? { properties_name_mask: [], title_prefix: "" }
            : r.data.data.params;
          if (!data.hasOwnProperty("setItem"))
            this.$store.commit("sidebars/changeRightSidebar", false);
          this.$store.commit("other/onChangeLoadingItem", false);
        })
        .catch((e) => {
          errorHandler(e);
        });
    });
    ProductsService.getDirectoryItems();
  },
  methods: {
    closeAcc,
    logger,
    showIncludedInKitItems(product) {
      if (product.identifier === "related-kit-items") {
        return this.product.schema[0].fields[1].data.value === "kit";
      } else {
        return true;
      }
    },
    onNewElement() {
      this.$store.commit("other/onChangeLoadingNewItem", true);
      this.$store.commit("sidebars/changeRightSidebar", false);
      this.productData = null;
      this.newProduct = true;
      this.editProduct = false;
      this.product = {
        schema: [],
      };
      this.getSchema();
      this.loadOptions = [];
    },
    openedDefault(product) {
      return (
        this.newProduct ||
        product.identifier === "general" ||
        product.identifier === "price-list"
      );
    },
    priceConversion() {
      this.showModal = false;
      const schema = this.product.schema;
      const priceIndex = schema.findIndex(
        (el) => el.identifier === "price-list"
      );
      const prices = schema[priceIndex].fields[0].data.prices;

      const elementsIndex = schema.findIndex(
        (el) => el.identifier === "related-kit-items"
      );
      const elements = schema[elementsIndex].fields[0].data
        .map((el) => {
          return el.value.prices;
        })
        .flat();

      const elementsValues = schema[elementsIndex].fields[0].data
        .map((el) => {
          return el.value;
        })
        .flat();

      /* Проходимся по строкам цен */
      prices.forEach((elem) => {
        let pricesBrand = [0, 0, 0];

        elements
          .filter((el) => el.price_type_id === elem.data.value)
          .forEach((elem2, elem2Index) => {
            const count = Number(elementsValues[elem2Index].count);
            pricesBrand[0] += elem2["price"] * count;
            pricesBrand[1] += elem2["price_up"] * count;
            pricesBrand[2] += elem2["price_customer"] * count;
          });

        /* Проходимся по полям */
        elem.fields.forEach((field, fieldIndex) => {
          field.value = pricesBrand[fieldIndex];
        });
      });
    },
    getSchema(type = "", id = null) {
      this.$store.commit("other/onChangeLoadingItem", true);
      // const generalIndex = this.product.schema.findIndex(el => el.identifier === 'general')
      ProductsService.getDirectorySchema(
        "",
        String(this.$store.state.products.section.id),
        id
      )
        .then((r) => {
          this.product = {
            ...r.data.data,
            schema: this.filterSchema(r),
          };
          // const generalIndex = this.product.schema.findIndex(el => el.identifier === 'general')
          // setTimeout(() => {
          //     if (this.$store.state.products.section.title) {
          //         this.product.schema[generalIndex].fields[5].data.push({
          //             title: this.$store.state.products.section.title,
          //             value: this.$store.state.products.section.id,
          //         })
          //     }
          // }, 0)
          this.$store.commit("sidebars/changeRightSidebar", true);
          this.$store.commit("other/onChangeLoadingItem", false);
          this.$store.commit("other/onChangeLoadingNewItem", false);
        })
        .catch((e) => {
          errorHandler(e);
        });
    },
    updateSchema(type = "", id = null) {
      const typeIndex = this.product.schema[0].fields.findIndex(
        (el) => el["input_name"] === "type"
      );
      const positionGroupIndex = this.product.schema[0].fields.findIndex(
        (el) => el["input_name"] === "position_group_ids"
      );

      const position =
        this.product.schema.length > 0
          ? this.product.schema[0].fields[positionGroupIndex].data
              .map((el) => {
                return el.value;
              })
              .join(",")
          : "";

      if (!this.disabledElements) {
        ProductsService.getDirectorySchema(
          this.product.schema.length > 0
            ? this.product.schema[0].fields[typeIndex].data.value
            : "",
          position,
          id
        )
          .then((r) => {
            const oldArray = this.product.schema.map((el) => {
              return el.identifier || "";
            });

            const newArray = r.data.data.schema.map((el) => {
              return {
                ...el,
                options: [],
                fields: el.fields.map((child) => {
                  let data = {
                    ...child,
                    loading: false,
                  };
                  if (typeof child.data === "object" && child.data !== null) {
                    if (child.data.hasOwnProperty("prices")) {
                      data["data"]["prices"] = child.data["prices"].map(
                        (price) => {
                          return {
                            ...price,
                            loading: false,
                          };
                        }
                      );
                    }
                  }
                  return data;
                }),
              };
            });

            newArray.forEach((el, index) => {
              if (!oldArray.includes(el.identifier)) {
                this.product.schema.splice(index, 0, el);
              } else if (el.identifier === "property") {
                this.product.schema.splice(index, 1);
                this.product.schema.splice(index, 0, el);
              }
            });

            this.$store.commit("sidebars/changeRightSidebar", true);

            if (!Array.isArray(this.$store.state.products.section.params)) {
              const titleIndex = this.product.schema.findIndex(
                (el) => el["identifier"] === "title"
              );
              this.product.schema[titleIndex].fields[0].data = "mask";
            }
          })
          .catch((e) => {
            errorHandler(e);
          });
      }
    },
    filterSchema(r) {
      return r.data.data.schema.map((el) => {
        return {
          ...el,
          options: [],
          fields: el.fields.map((child) => {
            let data = {
              ...child,
              loading: false,
            };
            if (typeof child.data === "object" && child.data !== null) {
              if (child.data.hasOwnProperty("prices")) {
                data["data"]["prices"] = child.data["prices"].map((price) => {
                  return {
                    ...price,
                    loading: false,
                  };
                });
              }
            }
            if (child.type === "related-kit-items") {
              if (child.data.length > 0) {
                data["data"] = child.data.map((elem) => {
                  return {
                    value: {
                      ...elem,
                      unit: {
                        data: {
                          name: elem.unit,
                        },
                      },
                    },
                  };
                });
              }
            }
            return data;
          }),
        };
      });
    },
    addProperty() {
      const property = {
        title: "",
        edit: false,
      };
      if (!this.selected.title) {
        this.$toast.error("Выберите характеристику");
      } else {
        this.productData["data"].push(this.selected);
        this.selected = property;
        this.newProperty = false;
        ProductsService.updateGroup(this.productData.id, {
          properties: this.productData["data"].map((el) => {
            return {
              property_id: el.value,
            };
          }),
        });
      }
    },
    updateMask() {
      if (this.productData.params.properties_name_mask.length > 0) {
        ProductsService.updateGroup(this.productData.id, {
          params: {
            properties_name_mask: this.productData.params.properties_name_mask.map(
              (el) => {
                return el.value;
              }
            ),
            title_prefix: this.productData.params.title_prefix,
          },
        }).then(() => {
          this.$toast.success("Маска сохранена");
        });
      }
    },
    closeRightSidebar() {
      this.newProduct = false;
      this.$store.commit("sidebars/changeRightSidebar", false);
    },
    getOptionsForProperties() {
      this.propertiesLoading = true;
      OtherService.getProperties().then((r) => {
        this.properties = r.data.data;
        this.loadOptions.push("properties");
        this.propertiesLoading = false;
      });
    },
    deleteProperty(i) {
      this.productData["data"].splice(i, 1);
      ProductsService.updateGroup(this.productData.id, {
        properties: this.productData["data"].map((el) => {
          return {
            property_id: el.value,
          };
        }),
      });
      this.deletedId = null;
      this.showModalDelete = false;
    },
    createDirectoryItem(copy = false) {
      this.createItemLoading = true;

      ProductsService.createDirectoryItems(this.formattingData(copy))
        .then(() => {
          this.closeRightSidebar();
          ProductsService.getDirectoryItems();
          this.newProduct = false;
          this.createItemLoading = false;
          if (copy) this.$toast.success(messages.copyProduct);
        })
        .catch((e) => {
          this.createItemLoading = false;
          errorHandler(e);
        });
    },
    updateDirectoryItem() {
      this.createItemLoading = true;

      ProductsService.updateDirectoryItems(
        this.currentProductId,
        this.formattingData()
      )
        .then(() => {
          this.editProduct = true;
          ProductsService.getDirectoryItems();
          this.createItemLoading = false;
          this.$toast.success(messages.updateProduct);
        })
        .catch((e) => {
          errorHandler(e);
        })
        .finally(() => {
          this.createItemLoading = false;
        });
    },
    formattingData(copy) {
      let data = {};
      this.product.schema.forEach((product) => {
        const { fields } = product;

        switch (product.identifier) {
          case "price-list":
            data[fields[0]["input_name"]] = fields[0].data["prices"].map(
              (el) => {
                let formattedPrice = {
                  price_type_id: el.data.value,
                };
                el.fields.forEach((elPrice) => {
                  formattedPrice[elPrice["input_name"]] = elPrice.value;
                });
                return formattedPrice;
              }
            );
            break;

          case "related-kit-items":
            if (fields[0].data.length > 0) {
              data["position_collection_id"] = "NxOpZowo9GmjKqdR";
              data["related_links"] = { kit: [] };
              data.related_links.kit = fields[0].data.map((el) => {
                return {
                  count: el.value.count,
                  id: el.value.id,
                };
              });
            }
            break;

          case "params":
            if (Object.hasOwn(data, "params")) data["params"] = {};
            const params = {};
            fields.forEach((field) => {
              const fieldName = field["input_name"].split(".")[1];
              params[fieldName] = field.data;
            });
            data["params"] = {
              ...data["params"],
              ...params,
            };
            break;

          case "title":
            // const propertyIndex = this.product.schema.findIndex(el => el.identifier === 'property')
            // if (fields[0].data === 'manual') {
            data[fields[2]["input_name"]] =
              fields[2].data.schema["value"] + (copy ? " - копия" : "");
            data["params"] = {
              ...data["params"],
              title_mode: "manual",
            };
            if (fields[3].data["value"].length > 0) {
              data[fields[3]["input_name"]] = fields[3].data["value"].map(
                (el) => {
                  return {
                    brand_id: el.brand.value,
                    title: el.title,
                  };
                }
              );
            }
            // } else {
            //     data['params'] = {
            //         "title_mode": "mask",
            //         "properties_for_mask_title": fields[1].data.schema[2].value
            //     }
            //     const divider = fields[1].data.schema[0].value
            //     const options_old = fields[1].data.schema[2].value.map(el => {
            //         return el.value
            //     })
            //     const options = this.product.schema[propertyIndex].fields[0].data
            //         .filter(el => options_old.includes(el.id))
            //         .map(el => {
            //             return el.value
            //         })
            //     data[fields[1]['input_name']] = [
            //         fields[1].data.schema[1].value,
            //         ...options
            //     ].join(divider)
            // }
            break;

          default:
            fields.forEach((field) => {
              if (field["input_name"] === "sku") {
                data["sku"] = field.data + (copy ? " - копия" : "");
              } else if (field.type === "input" || field.type === "numeric") {
                data[field["input_name"]] = field.data;
              } else if (field.type === "list" || field.type === "title") {
                data[field["input_name"]] = field.data.value;
              } else if (field.type === "groups") {
                if (!data.hasOwnProperty("position_group_ids"))
                  data["position_group_ids"] = [];
                field.data.forEach((el) => {
                  data.position_group_ids.push(el.value);
                });
              } else if (field["input_name"] === "bundle") {
                data["bundle"] = field.data.value;
              } else if (field.type === "properties") {
                if (!data.hasOwnProperty("properties")) {
                  if (field.data.filter((el) => el.value).length > 0) {
                    data["properties"] = [];

                    field.data.forEach((el) => {
                      let data_el = {
                        property_id: el.id,
                        value: el.value,
                      };
                      if (el.unit) data_el["unit_id"] = el.unit.value;
                      data.properties.push(data_el);
                    });
                  }
                }
              }
            });
            break;
        }
      });
      return data;
    },
  },
  beforeDestroy() {
    eventBus.$off("new-element");
    eventBus.$off("copy-element");
    eventBus.$off("update-schema");
    eventBus.$off("set-category-item");
  },
};
</script>

<style lang="scss" scoped>
.product-container {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  -moz-flex-direction: column;
  overflow-y: auto;

  .zem-dropdown {
    margin-top: 0;
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1024px) {
  .product-container {
    width: 100%;
    height: calc(100% - 45px);
    overflow: auto;
  }
}

@media screen and (max-width: 576px) {
  .product-container {
  }
}
</style>
